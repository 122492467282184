import React from "react";
import { isEmpty } from "lodash";
import "./style.scss";

const Page = (props) => {
  const { data } = props;
  return (
    <>
      {!isEmpty(data) ? (
        <div className="page-container container py-5">
          {!isEmpty(data.title) ? (
            <h1 className="spec-h" style={{ textAlign: "center" }}>
              {data.title}
            </h1>
          ) : null}
          <div className="row">
            <main className="page-content col-md-12">
              {!isEmpty(data.content) ? (
                <div
                  style={{ paddingTop: "50px" }}
                  dangerouslySetInnerHTML={{
                    __html: data.content,
                  }}
                />
              ) : null}
            </main>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default Page;
